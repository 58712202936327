<template>
  <section class="ys-submenu">
    <template v-for="subid in currentReportID"> 
      
      <!-- 有数据 -->
      <el-button 
        plain
        :key="subid"
        size="small"
        v-if="submenuData[subid]&&submenuData[subid].count"
        @click="submenuScroll(subid)"
      >
        {{ submenuText[subid] }}
        <template v-if="!submenuData[subid].ishide&&submenuText[subid]!='信用质量评分'">
          <span v-if="!submenuData[subid].total">{{submenuData[subid].count}}</span>
          <span v-else>{{submenuData[subid].total}}</span>
        </template>
      </el-button>
      <!-- 无数据 -->
      <el-button
        type="info"
        plain
        :key="subid"
        size="small"
        v-else-if="submenuData[subid]&&!isSingleTableIDS.includes(subid)"
      >
        {{ submenuText[subid] }}
        <!-- <span v-if="!submenuData[subid].ishide">{{submenuData[subid].count}}</span> -->
      </el-button>
    </template>
  </section>
</template>
<script>
import menutree from "./menutree"; 
export default {
  components: {
    menutree: menutree
  }, 
  props: { 
    isSingleTableIDS: { 
      type: Array,
      required: true
    },
    currentReportID: {
      type: Array,
      required: true
    },
    submenuData: {
      type: Object,
      required: true
    },
    submenuText: {
      type: Object,
      required: true
    }
  },
  methods: { 
    //二级导航锚点效果
    submenuScroll(subid) { 
      var content = document.getElementById('my-container')
 
      if(document.getElementById(subid)==null){
        return  
      }  
      let subtop = document.getElementById(subid).offsetTop;
      if(content){
        content.scrollTo({
          top: subtop - 150,
          behavior: "smooth"
        }); 
      }else{
        window.scrollTo({
          top: subtop - 150,
          behavior: "smooth"
        }); 
      }
    }
  }
};
</script>

<style>
</style>