<template >
  <div class="report-submenu">
    <el-submenu v-if="itemdata.children && itemdata.children.length >0 " :index="itemdata.id">
      <template slot="title">{{itemdata.text}}</template>
      <template v-for="child in itemdata.children">
        <menutree
          v-if="child.children && child.children.length > 0"
          :itemdata="child"
          :key="child.id"
          :reportType="reportType" 
          :reportID="reportID"  
        ></menutree>
        <el-menu-item
          v-else
          :key="child.id"
          :index="`/${reportType}/${reportID}/${child.url}`"
        >{{child.text}}</el-menu-item>
      </template>
    </el-submenu>

    <template v-else>
      <el-menu-item :index="`/${reportType}/${reportID}/${itemdata.url}`" @click="itemClick(itemdata.url)">{{ itemdata.text }}</el-menu-item>
    </template>
  </div>
</template>
<script>
export default {
  name: "menutree",
  props: ["itemdata", "reportType", "reportID"],
  methods:{
    itemClick(url){
      this.$emit('menuItemClick',url)
    }
  }
};
</script>
